import { NgModule } from '@angular/core';
import { CallbackPipe } from './pipes/callback.pipe';
import { MaterialDesignModule } from './design';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PopUpComponent } from './pop-up/pop-up.component';
import { CurrencyInputComponent } from './currency-input/currency-input.component';
import { DropdownSectorsComponent } from './dropdown-sectors/dropdown-sectors.component';
import { CommentsComponent } from './comments/comments.component';
import { InfoComponent } from './info/info.component';
import { InfoPopUpComponent } from './info/info-pop-up/info-pop-up.component';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { SafePipe } from './pipes/safe.pipe';
import { MatTabScrollToCenterDirective } from './scroll-to-center/scroll-to-center.directive';
import { HelpPopupComponent } from './help/help-popup/help-popup.component';
import { HelpComponent } from './help/help.component';
import { AssessmentGroupComponent } from './assessment-group/assessment-group.component';
import { ImpactScanGroupComponent } from './impact-scan-group/impact-scan-group.component';
import { MaxValidator } from './validators/max.validator';
import { IntroductionPopUpComponent } from './introduction-pop-up/introduction-pop-up.component';
import { CompletePopupComponent } from './onboarding-complete/complete-popup.component';
import { GeneralCardComponent } from './verify-details/general-card/general-card.component';
import { GovernanceCardComponent } from './verify-details/governance-card/governance-card.component';
import { ProductServiceCardComponent } from './verify-details/product-service-card/product-service-card.component';
import { ContactCardComponent } from './verify-details/contact-card/contact-card.component';
import { SubsidiariesCardComponent } from './verify-details/subsidiaries-card/subsidiaries-card.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NavtileComponent, SidenavComponent } from './sidenav';
import { VerifyDetailsComponent } from './verify-details/verify-details.component';
import { ViewBorderComponent } from './view-border/view-border.component';
import { BackgroundLogoComponent } from './background-logo/background-logo.component';
import { DisableForSmeViewerDirective } from './role-directives/disable-for-sme-viewer.directive';
import { DisableForEnterpriseLoggedInAsSmeDirective } from './role-directives/disable-for-enterprise-logged-in-as-sme.directive';
import { PlaceholderComponent } from './placeholder/placeholder.component';
import { SidenavIconsComponent } from './sidenav/sidenav-icons/sidenav-icons.component';
import { PopUpHeaderComponent } from './pop-up-header/pop-up-header.component';
import { DisableForRegularSMEDirective } from './role-directives/disable-for-non-enterprise.directive';
import { HideForAdminDirective } from '../admin/admin/hide-for-admin.directive';
import { TrimOnPasteDirective } from './directives/trim-on-paste.directive';
import { NaPipe } from './pipes/na.pipe';
import { CalculationInfoComponent } from './info/calculation-info/calculation-info.component';
import { CalculationInfoPopUpComponent } from './info/calculation-info/calculation-info-pop-up/calculation-info-pop-up.component';
import { DotComponent } from './dot/dot.component';
import { CalculatorComponent } from './calculator/calculator.component';
import { QuestionnaireHeaderComponent } from './questionnaire-header/questionnaire-header.component';
import { QuestionWithInfoComponent } from './question-with-info/question-with-info.component';
import { SubmitQuestionnairePopupComponent } from './submit-questionnaire-popup/submit-questionnaire-popup.component';
import { CountrySelectComponent } from './country-select/country-select.component';
import { UnansweredPopUpComponent } from './unanswered-pop-up/unanswered-pop-up.component';
import { UnansweredPopupSimpleComponent } from './unanswered-popup-simple/unanswered-popup-simple.component';
import { RelevantThemeComponent } from './relevant-theme/relevant-theme.component';
import { AssessmentRevampGroupComponent } from './assessment-group/assessment-group-revamp.component';
import { HeaderLessTabsDirective } from './headerless-tabs/header-less-tabs.directive';
import { HideForSsoEnterpriseDirective } from './role-directives/hide-for-sso-enterprise.directive';
import { PopUpHtmlComponent } from './pop-up-html/pop-up-html.component';
import { HideForFreeTrialDirective } from './role-directives/hide-for-free-trial.directive';
import { GriDisclaimerComponent } from './info/gri-disclaimer/gri-disclaimer.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { ExportDropdownComponent } from './export/export-dropdown/export-dropdown.component';

@NgModule({
  declarations: [
    CallbackPipe,
    SafePipe,
    MatTabScrollToCenterDirective,
    MaxValidator,
    PopUpComponent,
    PopUpHtmlComponent,
    CurrencyInputComponent,
    DropdownSectorsComponent,
    CommentsComponent,
    UnansweredPopUpComponent,
    UnansweredPopupSimpleComponent,
    InfoComponent,
    InfoPopUpComponent,
    IntroductionPopUpComponent,
    HelpComponent,
    HelpPopupComponent,
    AssessmentGroupComponent,
    AssessmentRevampGroupComponent,
    ImpactScanGroupComponent,
    CompletePopupComponent,
    GeneralCardComponent,
    GovernanceCardComponent,
    ProductServiceCardComponent,
    ContactCardComponent,
    SubsidiariesCardComponent,
    NavtileComponent,
    SidenavComponent,
    SidenavIconsComponent,
    VerifyDetailsComponent,
    ViewBorderComponent,
    BackgroundLogoComponent,
    DisableForRegularSMEDirective,
    DisableForSmeViewerDirective,
    HideForAdminDirective,
    DisableForEnterpriseLoggedInAsSmeDirective,
    HideForSsoEnterpriseDirective,
    HideForFreeTrialDirective,
    PlaceholderComponent,
    PopUpHeaderComponent,
    TrimOnPasteDirective,
    NaPipe,
    CalculationInfoComponent,
    CalculationInfoPopUpComponent,
    DotComponent,
    CalculatorComponent,
    QuestionnaireHeaderComponent,
    QuestionWithInfoComponent,
    SubmitQuestionnairePopupComponent,
    CountrySelectComponent,
    RelevantThemeComponent,
    HeaderLessTabsDirective,
    GriDisclaimerComponent,
    ExportDropdownComponent,
  ],
  providers: [MatDatepickerModule, NaPipe, MatNativeDateModule, { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  imports: [
    MaterialDesignModule,
    TranslateModule,
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatRadioModule,
  ],
  exports: [
    CallbackPipe,
    SafePipe,
    MatTabScrollToCenterDirective,
    MaxValidator,
    PopUpComponent,
    PopUpHtmlComponent,
    CurrencyInputComponent,
    DropdownSectorsComponent,
    CommentsComponent,
    UnansweredPopUpComponent,
    UnansweredPopupSimpleComponent,
    InfoComponent,
    InfoPopUpComponent,
    IntroductionPopUpComponent,
    HelpComponent,
    HelpPopupComponent,
    AssessmentGroupComponent,
    AssessmentRevampGroupComponent,
    ImpactScanGroupComponent,
    CompletePopupComponent,
    GeneralCardComponent,
    GovernanceCardComponent,
    ProductServiceCardComponent,
    ContactCardComponent,
    SubsidiariesCardComponent,
    NavtileComponent,
    SidenavComponent,
    SidenavIconsComponent,
    VerifyDetailsComponent,
    ViewBorderComponent,
    BackgroundLogoComponent,
    DisableForRegularSMEDirective,
    DisableForSmeViewerDirective,
    HideForSsoEnterpriseDirective,
    HideForAdminDirective,
    DisableForEnterpriseLoggedInAsSmeDirective,
    HideForFreeTrialDirective,
    PlaceholderComponent,
    PopUpHeaderComponent,
    TrimOnPasteDirective,
    NaPipe,
    CalculationInfoComponent,
    DotComponent,
    CalculatorComponent,
    QuestionnaireHeaderComponent,
    QuestionWithInfoComponent,
    CountrySelectComponent,
    RelevantThemeComponent,
    HeaderLessTabsDirective,
    GriDisclaimerComponent,
    ExportDropdownComponent,
  ],
})
export class SharedModule {}
