import { ScoringStatus } from './status.model';
import { ImpactDirection } from './impact.direction';
import { QuestionnaireType } from './questionnaire.type';
import { UserStatus } from '../../assessment-list/user-status.enum';
import { GriEntityModel, RelevantThemeModel } from '../../../shared/relevant-theme/relevant-theme.model';

export interface ScoringWrapperModel {
  scoring: ManagedScore;
  deprecatedLatest: boolean;
  deprecatedScoring: ScoringModel;
}

export interface ScoringModel {
  id: string;
  status: ScoringStatus;
  type: QuestionnaireType;
  createdAt: string;
  updatedAt: string;
  reportingPeriodStart: string;
  reportingPeriodEnd: string;
  scores: Scores;
  firstName: string;
  lastName: string;
  userStatus: UserStatus;

  // only initiated if the assessmentType is ESG_ASSESSMENT
  assessmentVersion?: AssessmentVersion;
  triggerableQuestionIds?: string[];
  impactMateriality?: GriEntityModel[];
  financialMateriality?: GriEntityModel[];
  prioritisedThemes?: GriEntityModel[];
}

export interface Scores {
  IMPACT: {
    scores: ImpactScore;
  };
  ESG: {
    scores: ESGScore;
  };
  SDG: {
    scores: SDGScore;
  };
  GRI: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    scores: any;
    counts: GRIPercentage;
  };
  EU_TAXONOMY: {
    compliant: boolean;
  };
  RATIO: {
    scores: RatioScore;
  };
  MATERIALITY: {
    doubleMateriality: RelevantThemeModel[][];
    financialMateriality: RelevantThemeModel[][];
    impactMateriality: RelevantThemeModel[][];
  };
}

export interface ImpactScore {
  ECONOMIC: ImpactValue;
  ENVIRONMENTAL: ImpactValue;
  SOCIAL: ImpactValue;
  GOVERNANCE: ImpactValue;
}

export interface ImpactValue {
  direction: ImpactDirection;
  scale: number;
}

export interface GRIPercentage {
  ECONOMIC: number;
  ENVIRONMENTAL: number;
  SOCIAL: number;
  GENERAL: number;
}

export interface ESGScore {
  ECONOMIC: ESGScoreValue;
  ENVIRONMENTAL: ESGScoreValue;
  SOCIAL: ESGScoreValue;
  GOVERNANCE: ESGScoreValue;
}

export interface RatioScore {
  GHG: number;
  WATER_INDUSTRIAL: number;
  WATER_NON_INDUSTRIAL: number;
  ENERGY_INTENSITY: number;
  ENERGY_CONSUMPTION: number;
}

export interface ESGScoreValue {
  maxScore: number;
  unweightedScore: number;
}

export interface SDGScore {
  1?: SDGScoreValue;
  2?: SDGScoreValue;
  3?: SDGScoreValue;
  4?: SDGScoreValue;
  5?: SDGScoreValue;
  6?: SDGScoreValue;
  7?: SDGScoreValue;
  8?: SDGScoreValue;
  9?: SDGScoreValue;
  10?: SDGScoreValue;
  11?: SDGScoreValue;
  12?: SDGScoreValue;
  13?: SDGScoreValue;
  14?: SDGScoreValue;
  15?: SDGScoreValue;
  16?: SDGScoreValue;
  17?: SDGScoreValue;
}

export interface SDGScoreValue {
  maxScore: number;
  score: number;
  contributing: boolean;
}

// eslint-disable-next-line no-shadow
export enum AssessmentVersion {
  OLD = 'OLD',
  REVAMP = 'REVAMP',
  MANAGED = 'MANAGED',
}

export interface LastAssessmentMetadata {
  assessmentVersion: AssessmentVersion;
  firstId: string | undefined;
}

export interface ManagedScore {
  esg: ManagedEsgScore[];
  gri: ManagedGriScore[];
  sdg: ManagedSdgScore[];
  esrs: ManagedEsrsScore[];
  euTax: ManagedEuTaxonomyScore;
  materiality: CsrdMateriality;
  firstName: string;
  lastName: string;
}

export interface Subcategory {
  subcategory: string;
  score: number;
  maxScore: number;
}

export interface SubcategoryTranslation {
  language: string;
  subcategories: Subcategory[];
}

export interface ManagedEsgScore {
  category: string;
  maxScore: number;
  score: number;
  subcategoryTranslations: SubcategoryTranslation[];
}

export interface ManagedGriScore {
  category: string;
  maxScore: number;
  score: number;
  disclosureCode: string;
  disclosureTranslations: Translation[];
  standardCode: string;
  standardTranslations: Translation[];
}

export interface Translation {
  languageCode: string;
  title: string;
}

export interface ManagedEsrsScore {
  category: string;
  maxScore: number;
  score: number;
  disclosureCode: string;
  disclosureTranslations: Translation[];
  standardCode: string;
  standardTranslations: Translation[];
}

export interface ManagedSdgTarget {
  code: string;
  translations: TargetTranslations[];
}

export interface GoalTranslation {
  title: string;
  description: string;
  languageCode: string;
}

export interface TargetTranslations {
  description: string;
  languageCode: string;
}

export interface ManagedSdgScore {
  goalCode: string;
  goalTranslations: GoalTranslation[];
  targets?: ManagedSdgTarget[];
}

export interface ManagedEuTaxonomyScore {
  compliant: boolean;
}

export interface GriScore {
  standardCounts: number;
  disclosureCount: number;
  category: string;
}

export interface OldGriScore {
  name: string;
  score: number;
}

export interface GriCoexistenceModel {
  oldAssessment: boolean;
  oldScore: OldGriScore[];
  score: GriScore[];
}

export interface CsrdScore {
  standardCounts: number;
  disclosureCount: number;
  category: string;
}

export interface MaterialitySubtheme {
  disclosureCode: string;
  disclosureNameUK: string;
  disclosureNameNL: string;
}

export interface MaterialityTheme {
  impactIndex: number;
  financialIndex: number;
  standardCode: string;
  standardNameUK: string;
  standardNameNL: string;
  explanationUK: string;
  explanationNL: string;
  category: string;
  subthemes: MaterialitySubtheme[];
}

export interface CsrdMateriality {
  doubleMateriality: RelevantThemeModel[][];
  financialMateriality: RelevantThemeModel[][];
  impactMateriality: RelevantThemeModel[][];
  matrixRankedThemes: MaterialityTheme[];
}

export interface CsrdCoexistenceModel {
  oldAssessment: boolean;
  materiality?: CsrdMateriality;
  oldScore?: CsrdMateriality;
  score: CsrdScore[];
}

export interface RelevantDisclosure {
  code: string;
  name: string;
  percentage: string;
}

export interface RelevantStandard {
  code: string;
  name: string;
  category: string;
  averageScore?: string;
  relevantDisclosures: RelevantDisclosure[];
}
