import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SdgCardModel } from '../../models';
import { ScoreboardService } from '../../scoreboard.service';
import { Assessment, Topic } from '../../models/topic.model';
import { OptionItem } from '../../shared/slider-toggle/slider-group.component';
import { SdgPopupModel } from '../popup/sdg-popup.model';
import { MatDialog } from '@angular/material/dialog';
import { GoalTranslation, ManagedSdgScore, ManagedSdgTarget } from '../../models/scoring.model';
import { SdgPopupComponent } from '../popup/sdg-popup.component';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { EeveryTranslateService } from '../../../../shared/eevery.translate.service';

@Component({
  selector: 'app-sdg-top-cards',
  templateUrl: './sdg-top-cards.component.html',
  styleUrls: ['./sdg-top-cards.component.scss'],
  animations: [
    trigger('changeAnimation', [
      state('hide', style({ opacity: 0 })),
      state('show', style({ opacity: 1 })),
      transition('hide => show', [animate('0.25s ease-in')]),
      transition('show => hide', [animate('0.25s ease-in')]),
    ]),
  ],
})
export class SdgTopCardsComponent implements OnInit {
  @Input()
  scores: Topic[] = [];

  @Input()
  companyName!: string;

  @Output()
  placeholderButtonClick = new EventEmitter<void>();

  cards!: SdgCardModel[][];

  sliderItems!: OptionItem[];

  sliderPositions: number[] = [0, 0];

  targetsByPosition: ManagedSdgScore[][] = [];

  animation = false;

  stopAnimation = true;

  constructor(
    private scoreboardService: ScoreboardService,
    private dialog: MatDialog,
    private translationService: EeveryTranslateService,
  ) {}

  ngOnInit(): void {
    this.scoreboardService.currentTopic.subscribe((scores) => {
      if (scores) {
        this.scores = scores;
        this.scoresInit();
      }
    });
  }

  scoresInit(): void {
    this.cards = [];
    this.sliderItems = [];
    this.targetsByPosition = [];

    if (this.scores) {
      this.scores
        .filter((topic) => topic.selected || topic.indeterminate)
        .reverse()
        .forEach((score) => {
          score.assessments
            .filter((assessment) => assessment.selected)
            .reverse()
            .forEach((assessment) => {
              const filteredCards: SdgCardModel[] = [];
              const rawCards = this.scoreboardService.getCards();

              if (assessment.scoring?.deprecatedLatest) {
                this.addCardsForOldAssessment(assessment, rawCards, filteredCards);
                const managedSdgScore: ManagedSdgScore[] = [
                  {
                    targets: [],
                    goalCode: '',
                    goalTranslations: [],
                  },
                ];

                this.targetsByPosition.push(managedSdgScore);
              } else if (assessment.scoring.scoring) {
                this.addCards(assessment, rawCards, filteredCards);
                this.targetsByPosition.push(assessment.scoring.scoring.sdg);
              }
              this.cards.push(filteredCards);
              this.sliderItems.push({ alias: assessment.name, id: assessment.id });
            });
        });
      this.sliderPositions = [this.sliderItems.length - 1, 0];
    }
    this.sortCardsAndTargetsByGoalCode();
  }

  private sortCardsAndTargetsByGoalCode(): void {
    this.cards.forEach((cardSet) => {
      cardSet.sort((a, b) => +a.sdgCode - +b.sdgCode);
    });
    this.targetsByPosition.forEach((targetsSet) => {
      targetsSet.forEach((targetSet) => {
        targetSet.targets?.sort((a, b) => +a.code - +b.code);
        targetSet.targets?.sort((a, b) => this.sortSdgTargetsByCode(a, b));
      });
      targetsSet.splice(4, targetsSet.length - 4);
      targetsSet.sort((a, b) => +a.goalCode - +b.goalCode);
    });
  }

  private sortSdgTargetsByCode(a: ManagedSdgTarget, b: ManagedSdgTarget): number {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,no-restricted-globals
    const parseCodePart = (part: string) => (isNaN(parseInt(part, 10)) ? `_${part}` : `${part}`.padStart(3, '0'));

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const normalizeCode = (code: string) => code.split('.').map(parseCodePart).join('.');

    const aCodeNormalized = normalizeCode(a.code);
    const bCodeNormalized = normalizeCode(b.code);

    return aCodeNormalized.localeCompare(bCodeNormalized);
  }

  addCards(assessment: Assessment, rawCards: SdgCardModel[], filteredCards: SdgCardModel[]): void {
    for (let i = 0; i < 4; i += 1) {
      if (assessment.scoring.scoring.sdg !== null) {
        const sdgScore = assessment.scoring.scoring.sdg[i];
        const card = rawCards.find((c) => c.sdgCode === sdgScore.goalCode);

        if (card) {
          card.title = this.getTranslatedTitle(sdgScore.goalTranslations);
          filteredCards.push(card);
        }
      }
    }
  }

  private getTranslatedTitle(goalTranslations: GoalTranslation[]): string {
    const language = this.translationService.getLanguage().replace('_', '-').replace('GB', 'UK');
    const translation = goalTranslations.find((t) => t.languageCode === language);

    if (translation) {
      return translation.title;
    }
    if (goalTranslations[0].title) {
      return goalTranslations[0].title;
    }

    return 'Unknown';
  }

  addCardsForOldAssessment(assessment: Assessment, rawCards: SdgCardModel[], filteredCards: SdgCardModel[]): void {
    const sdgScore = assessment.scoring.deprecatedScoring.scores.SDG.scores;

    rawCards.forEach((card) => {
      const cardNumber = card.sdgCode;

      // @ts-ignore
      if (sdgScore[cardNumber] && sdgScore[cardNumber].contributing) {
        // eslint-disable-next-line no-param-reassign
        card.isGood = true;
        filteredCards.push(card);
      }
    });
  }

  toggleResults(event: { sliderId: string; sliderPositions: number[] }): void {
    this.animation = true;

    setTimeout(() => {
      this.animation = false;
      this.sliderPositions = event.sliderPositions;
    }, 250);
  }

  openCardPopup(card: SdgCardModel, index: number): void {
    const position = this.sliderPositions[0];
    let sdgTargets: ManagedSdgTarget[] = [];

    if (position !== null && this.targetsByPosition[position][index]) {
      sdgTargets = this.targetsByPosition[position][index].targets ?? [];
    }

    const data: SdgPopupModel = {
      sdgGoalCode: card.sdgCode,
      sdgGoalTitle: card.title,
      sdgTargets,
    };

    this.dialog.open(SdgPopupComponent, { width: '60vw', autoFocus: false, data });
  }

  toggleDropdown(): void {
    this.placeholderButtonClick.emit();
  }
}
